const RESERVED = "Reservada";
const ACTIVE = "Activa";
const FINISHED = "Finalizada";
const CANCELLED = "Cancelada";

const NUM_RESERVED = 0;
const NUM_ACTIVE = 1;
const NUM_FINISHED = 2;
const NUM_CANCELLED = 3;

const get = {};
get[NUM_RESERVED] = RESERVED;
get[NUM_ACTIVE] = ACTIVE;
get[NUM_FINISHED] = FINISHED;
get[NUM_CANCELLED] = CANCELLED;

export default {
  get,
  RESERVED,
  ACTIVE,
  FINISHED,
  CANCELLED,
  NUM_RESERVED,
  NUM_ACTIVE,
  NUM_FINISHED,
  NUM_CANCELLED
};
