<template>
  <div class="d-flex">
    <div class="icon-container">
      <z-icon name="Restaurant" class="mr-2" />
    </div>
    <div>
      <p id="name" class="font-weight-bold m-0 mb-1">{{ name }}</p>
      <p id="address" class="mb-0">{{ address }}</p>
      <p v-if="information" id="info" class="mb-0">
        <b>D:</b> {{ information }}
      </p>
      <p id="phone"><b>T:</b> {{ phone }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddressItem",

  props: {
    address: {
      type: String,
      required: true
    },
    information: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      required: true
    },
    phone: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.icon-container {
  width: 30px;
}
.restaurant-icon g g path {
  fill: $sapphire !important;
}
</style>
