<template>
  <status-badge :status-name="statusName" :type="colorVariant" />
</template>

<script>
import StatusBadge from "@zubut/common/src/components/StatusBadge";
import ReservationStatus from "@/constants/reservations/status";

export default {
  name: "StatusReservation",
  components: { StatusBadge },
  props: {
    status: {
      type: Number,
      required: true
    }
  },
  computed: {
    statusName() {
      return ReservationStatus.get[this.status];
    },
    colorVariant() {
      switch (this.status) {
        case ReservationStatus.NUM_RESERVED:
        case ReservationStatus.NUM_ACTIVE:
          return "green";
        case ReservationStatus.NUM_FINISHED:
          return "yellow";
        case ReservationStatus.NUM_CANCELLED:
          return "red";
        default:
          return "yellow";
      }
    }
  }
};
</script>
