<template>
  <div class="hour-rent-detail">
    <b-card>
      <div class="card-body">
        <b-row>
          <b-col>
            <go-back-title
              :title="title"
              with-action
              @back-action="$router.go(-1)"
            />
          </b-col>
        </b-row>
        <hr class="mb-4" />
        <b-row class="pt-2">
          <b-col md="6" class="pr-3">
            <div class="border rounded p-4">
              <b-row v-if="isLoading">
                <b-col>
                  <loading-spinner />
                </b-col>
              </b-row>
              <b-row v-else>
                <b-col class="driver">
                  <span data-testid="driverHeader" class="h6 font-weight-bold">
                    Desglose de tarifa
                  </span>
                  <div class="mt-3 mb-4">
                    <z-map
                      id="service-detail-map"
                      :center="mapCenter"
                      :destinations="destinations"
                    />
                  </div>
                  <div>
                    <address-item
                      :address="reservation.origin.address"
                      :information="reservation.origin.information"
                      :name="reservation.origin.name"
                      :phone="reservation.origin.phone"
                    />
                  </div>
                  <div>
                    <service-item
                      v-for="service in services"
                      :key="`${service.id}-service`"
                      :service="service"
                      class="mb-2"
                    />
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-col>
          <b-col md="6" class="mt-4 mt-md-0 pl-3">
            <div class="border rounded p-4">
              <loading-spinner v-if="isLoading" />
              <payment-detail v-else :reservation="reservation" />
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import Reservations from "@/services/reservations";
import GoBackTitle from "@zubut/common/src/components/GoBackTitle";
import ZMap from "@zubut/common/src/components/ZMap";
import { makeShortUUID } from "@/utils/strings";
import AddressItem from "./AddressItem";
import PaymentDetail from "./PaymentDetail";
import ServiceItem from "./ServiceItem";
import Http from "@/constants/http";

export default {
  name: "HourRentDetail",

  components: {
    AddressItem,
    GoBackTitle,
    PaymentDetail,
    ServiceItem,
    ZMap
  },

  data() {
    return {
      reservation: null,
      isLoading: true
    };
  },

  computed: {
    destinations() {
      if (this.reservation.origin != null) {
        return [{ ...this.reservation.origin, order: 0 }];
      }
      return [];
    },

    mapCenter() {
      return (
        this.reservation?.origin?.position || {
          lat: 20.6737777,
          lng: -103.4054536
        }
      );
    },

    title() {
      if (this.reservation && this.reservation.id) {
        return `Detalle de orden ${makeShortUUID(this.reservation.id)}`;
      }
      return "";
    },

    services() {
      let services = [];
      if (this.reservation && this.reservation.reservationBlocks) {
        this.reservation.reservationBlocks.forEach(reservationBlock => {
          let service = {
            start: reservationBlock.start,
            end: reservationBlock.end
          };
          reservationBlock.services.forEach(item => {
            service = {
              ...service,
              id: item.serviceId,
              status: item.serviceStatus
            };
            services.push(service);
          });
        });
      }
      return services;
    }
  },

  beforeMount() {
    this.getOrderDetail();
  },

  methods: {
    getOrderDetail() {
      this.isLoading = true;
      Reservations.getDetails(this.$route.params.id)
        .then(res => {
          this.reservation = res.reservation;
          this.reservation.status = Number.isInteger(this.reservation.status)
            ? this.reservation.status
            : 2;
          this.isLoading = false;
        })
        .catch(err => {
          if (err.statusCode === Http.NOT_FOUND) {
            this.$store.commit("setDisplayNotFound", true);
          } else {
            this.$captureError(err);
            this.isLoading = false;
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
#service-detail-map {
  height: 300px;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
}
</style>
