<template>
  <div
    class="service-item d-flex justify-content-between border rounded"
    @click="goToServiceDetail"
  >
    <div class="d-flex">
      <div class="p-3">
        <z-profile-image size="sm" type="driver" :image="driverImage" />
      </div>
      <div class="py-3">
        <p class="mb-0 font-weight-bold">Servicio {{ serviceId }}</p>
        <p class="mb-0">{{ serviceTime }} de {{ startTime }} a {{ endTime }}</p>
      </div>
    </div>
    <div class="p-3 d-flex align-items-center">
      <status-service
        :status="service.status"
        :cancel-reason="service.cancelReason"
      />
    </div>
  </div>
</template>

<script>
import { makeShortUUID } from "@/utils/strings";
import StatusService from "@zubut/common/src/components/StatusService";
import ZProfileImage from "@/app/components/ZProfileImage";
import capitalize from "lodash/capitalize";
import moment from "moment";

export default {
  name: "ServiceItem",

  components: {
    StatusService,
    ZProfileImage
  },

  props: {
    service: {
      type: Object,
      required: true
    }
  },

  computed: {
    endTime() {
      return moment(this.service.end).format("hh:mm a");
    },

    driverImage() {
      if (this.service.courier && this.service.courier.image) {
        return this.service.courier.image;
      }
      return "";
    },

    serviceId() {
      return makeShortUUID(this.service.id);
    },

    serviceTime() {
      return capitalize(
        moment(this.service.start)
          .format("ddd DD MMM YYYY")
          .replace(/\./g, "")
      );
    },

    startTime() {
      return moment(this.service.start).format("hh:mm a");
    }
  },

  methods: {
    goToServiceDetail() {
      this.$router.push({
        name: "serviceDetail",
        params: { id: this.service.id }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.service-item {
  font-size: 12px;

  &:hover {
    background: rgba($black, 0.01);
    cursor: pointer;
  }

  img {
    height: 40px;
    width: 40px;
  }

  ::v-deep .badge {
    font-size: 10px;
  }
}
</style>
