<template>
  <div class="payment">
    <b-row>
      <b-col cols="4" md="4" class="d-flex justify-content-between">
        <span class="h6 font-weight-bold">
          Desglose de tarifa
        </span>
      </b-col>
      <b-col cols="8" md="8" class="text-right">
        <div :class="{ 'account-user-status-name': isAccountUser }">
          <status-reservation :status="reservation.status" />
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col
        class="d-flex justify-content-between align-items-end font-weight-semi-bold"
      >
        <div class="h6 font-weight-bold">Total</div>
        <div class="total-amount mb-0 font-weight-bold">
          {{ totalFormatted }}
        </div>
      </b-col>
    </b-row>
    <b-list-group flush class="list list-cost mt-0">
      <b-list-group-item class="d-flex justify-content-between px-0">
        Tarifa por hora
        <span>{{ costPerHourFormatted }}</span>
      </b-list-group-item>
      <b-list-group-item class="d-flex justify-content-between px-0">
        Tiempo agendado
        <span>{{ totalHours }} hrs</span>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import { formatMoney } from "@/utils/money";
import serviceRate from "@/mixins/serviceRate";
import ConstantsReservationStatus from "@/constants/reservations/status";
import StatusReservation from "@/app/components/StatusReservation";

export default {
  name: "PaymentDetail",

  components: {
    StatusReservation
  },

  mixins: [serviceRate],

  props: {
    isAccountUser: {
      type: Boolean,
      default: false
    },
    isConsolidatedUser: {
      type: Boolean,
      default: false
    },
    reservation: {
      type: Object,
      required: true
    }
  },

  computed: {
    costPerHourFormatted() {
      return formatMoney(this.reservation.cost.costPerHour).replace(/.00/, "");
    },

    isFinished() {
      if (this.reservation.status === ConstantsReservationStatus.NUM_FINISHED) {
        return true;
      }
      return false;
    },

    statusName() {
      return ConstantsReservationStatus.get[this.reservation.status];
    },

    totalFormatted() {
      return `${formatMoney(this.reservation.cost.total).replace(/.00/, "")}  `;
    },

    totalHours() {
      return this.reservation.cost.totalHours;
    }
  }
};
</script>

<style lang="scss" scoped>
.payment {
  font-size: 12px;

  i {
    font-size: 37px;
    margin-right: 10px;
  }

  .reservation-status {
    font-size: 10px;
  }

  .total-amount {
    font-size: 16px;
  }

  .list-cost {
    text-align: right;
    line-height: 22px;

    h5 {
      text-align: right;
      margin-top: -20px;
      margin-right: 70px;
      font-size: 13px !important;
      margin-bottom: 2px;
      font-weight: normal;
    }

    .list-group-item {
      padding: 0.75em 0px;
      font-size: 12px;
      position: relative;

      .detail-quantity {
        position: absolute;
        left: 200px;
      }
    }
  }
}
</style>
