const SERVER_ERROR = 500;
const BAD_REQUEST = 400;
const NOT_FOUND = 404;
const UNPROCESSABLE_ENTITY = 422;
const SUCCESS = 200;

export default {
  SERVER_ERROR,
  BAD_REQUEST,
  NOT_FOUND,
  UNPROCESSABLE_ENTITY,
  SUCCESS
};
